<template>
    <div>
      <form @submit.prevent="addPriceRange" class="form-group">
        <div class="form-group">
          <label for="priceFrom" class="form-label">السعـر مـن</label>
          <input type="text" v-model="priceFrom" id="priceFrom" class="form-control" />
        </div>
        <div class="form-group">
          <label for="priceTo" class="form-label">السـعر الـى</label>
          <input type="text" v-model="priceTo" id="priceTo" class="form-control" />
        </div>
        <div class="form-group">
          <label for="sellingPrice" class="form-label">نسبـة الزيـادة</label>
          
          <div class="col-3 row">
            <input type="text" v-model="sellingPrice" id="sellingPrice" class="form-control col-10" />
         <h4 class="col-1">%</h4>
          </div>
        </div>
        <button type="submit" class="btn btn-primary">إضــافــة</button>
      </form>
    </div>
  </template>
  
  <script>
    import axios from 'axios';
    export default {
        data() {
        return {
            priceFrom: '',
            priceTo: '',
            sellingPrice: '',
        };
        },
        methods: {
        addPriceRange() {
            axios.post('https://boutiquette-store.ly/add-price-range', {
            price_from: this.priceFrom,
            price_to: this.priceTo,
            selling_price: this.sellingPrice,
            })
            .then(response => {
             
                this.$router.push('/home');
            })
            .catch(error => {
              null
            });
        },
        },
    };
  </script>
<style>
    label{
        color:black !important;
    }
</style>